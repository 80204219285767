/**
 * Created by poulnike on 10.01.2018.
 */

// ======================
// countdown.js start
// ======================


$(function () {

    var b_name = '.countdown-clock';
    var b = $(b_name); // блок

    if (b.length) {

        b.countdown('2018/01/15', function(event) {
            var $this = $(this).html(event.strftime(''
                + '<div class="countdown-section countdown-day">' +
                    '<span class="countdown-count">%d</span>' +
                    '<span class="countdown-name">дня</span>' +
                '</div>'
                + '<div class="countdown-section countdown-hours">' +
                '<span class="countdown-count">%H</span>' +
                '<span class="countdown-name">часов</span>' +
                '</div>'
                + '<div class="countdown-section countdown-min">' +
                '<span class="countdown-count">%M</span>' +
                '<span class="countdown-name">минут</span>' +
                '</div>'
                + '<div class="countdown-section countdown-sec">' +
                '<span class="countdown-count">%S</span>' +
                '<span class="countdown-name">секунд</span>' +
                '</div>'));
        });

    }

});

// ======================
// countdown.js end
// ======================