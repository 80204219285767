/**
 * Created by poulnike on 17.04.2019.
 */

// ======================
// datatable.js start
// ======================


$(function () {

    var b_name = '.datatable';
    var b = $(b_name); // блок

    if (b.length) {


        $('#example').DataTable( {
            responsive: true,
            "ajax": "data/kpdgd.json",
            "columns": [
                { "data": "number" },
                { "data": "name" },
                { "data": "GD" },
                { "data": "narod" },
                { "data": "expert" },
                { "data": "citata" },
                { "data": "kpdgd6" }
            ]
        } );


    }

});

// ======================
// datatable.js end
// ======================