/**
 * Created by poulnike on 11.01.2018.
 */

// ======================
// nav.js start
// ======================


$(function () {

    //var b_name = 'nav';
    var b = $(".index-features-nav .features"); // блок

    if (b.length) {

        var $features = $(".index-features-nav .features");
        var position = $features.offset().top + 40;

        $(window).scroll(function () {
            var top = $(this).scrollTop();

            if (top > position && !$features.hasClass("fixed")) {
                $features.addClass("fixed fadeInDown");
            } else if (top < position && $features.hasClass("fixed")) {
                $features.removeClass("fixed fadeInDown");
                $features.find("li:eq(0) .nav-link").addClass("active")
            }
        });

        $('body').scrollspy({
            target: '.index-features-nav'
        });

        setTimeout(function () {
            $(".nav-link").removeClass("featureFadeInDown");
        }, 1500);

    }

});

// ======================
// nav.js end
// ======================